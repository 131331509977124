:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}
@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }
  .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input:required,
  .annotationLayer .choiceWidgetAnnotation select:required,
  .annotationLayer .textWidgetAnnotation :is(input, textarea):required {
    outline: selectedItem solid 1.5px;
  }
  .annotationLayer .linkAnnotation:hover {
    backdrop-filter: invert(100%);
  }
}
.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
}
.annotationLayer[data-main-rotation='90'] .norotate {
  transform: rotate(270deg) translateX(-100%);
}
.annotationLayer[data-main-rotation='180'] .norotate {
  transform: rotate(180deg) translate(-100%, -100%);
}
.annotationLayer[data-main-rotation='270'] .norotate {
  transform: rotate(90deg) translateY(-100%);
}
.annotationLayer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.annotationLayer section {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  margin: 0;
  transform-origin: 0 0;
}
.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}
.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.annotationLayer
  :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton)
  > a:hover {
  opacity: 0.2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}
.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input,
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .textWidgetAnnotation :is(input, textarea) {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer .textWidgetAnnotation :is(input, textarea):required {
  outline: red solid 1.5px;
}
.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}
.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled] {
  background: 0 0;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .textWidgetAnnotation :is(input, textarea):hover {
  border: 2px solid var(--input-hover-border-color);
}
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .textWidgetAnnotation :is(input, textarea):hover {
  border-radius: 2px;
}
.annotationLayer .choiceWidgetAnnotation select:focus,
.annotationLayer .textWidgetAnnotation :is(input, textarea):focus {
  background: 0 0;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-image: none;
  background-color: transparent;
}
.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}
.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  background-color: CanvasText;
  content: '';
  display: block;
  position: absolute;
}
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  height: 80%;
  left: 45%;
  width: 1px;
}
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  transform: rotate(45deg);
}
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  transform: rotate(-45deg);
}
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}
.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}
.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  appearance: none;
}
.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}
.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}
.annotationLayer .popupWrapper {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  width: 100%;
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
}
.annotationLayer .popup {
  position: absolute;
  max-width: calc(180px * var(--scale-factor));
  background-color: #ff9;
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}
.annotationLayer .popup > *,
.annotationLayer .richText > * {
  font-size: calc(9px * var(--scale-factor));
}
.annotationLayer .popup h1 {
  display: inline-block;
}
.annotationLayer .popupDate {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
}
.annotationLayer .popupContent {
  border-top: 1px solid #333;
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}
.annotationLayer .richText > * {
  white-space: pre-wrap;
}
.annotationLayer .caretAnnotation,
.annotationLayer .circleAnnotation svg ellipse,
.annotationLayer .fileAttachmentAnnotation,
.annotationLayer .freeTextAnnotation,
.annotationLayer .highlightAnnotation,
.annotationLayer .inkAnnotation svg polyline,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .polygonAnnotation svg polygon,
.annotationLayer .polylineAnnotation svg polyline,
.annotationLayer .squareAnnotation svg rect,
.annotationLayer .squigglyAnnotation,
.annotationLayer .stampAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .underlineAnnotation {
  cursor: pointer;
}
.annotationLayer section svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.annotationLayer .annotationTextContent {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  user-select: none;
  pointer-events: none;
}
.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}
